
export const environment = {
   production: false,
   appVersion: "v726demo1",
   USERDATA_KEY: "authf649fc9a5f55",
   isMockEnabled: "true",
   googleApi: "AIzaSyAH_Vkr4N8HMs6zbtjYWZgUSqnwWrLp7Ss", 
   prodHttp: "https://apitest.childcarephotos.com.au/uploads/product_images/",
   childHttp: "https://apitest.childcarephotos.com.au/uploads/photos/",
   apiUrl: "https://apitest.childcarephotos.com.au/"
};
